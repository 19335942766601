import { Ctrl } from ":mods";
import { splitProps } from "solid-js";

export function TopNotesMessagesQuestions(props: Ctrl.Nav.model.TopNavJSXItemProps) {
  return (
    <Ctrl.Nav.TopElement
      base={props.base}
      arrowMode="hidden"
      groupClass="flex flex-row items-center space-x-2"
      itemsClass="flex flex-col bg#p px-2 py-1 space-y-3 mt-5 !w-200px -translate-x-70%"
      // items={<div class="capitalize">you have no notifications</div>}
      // items={<div class="capitalize">you have no notifications</div>}
    >
      <section class="relative flex flex-row items-center !w-full space-x-2">
        <IconWithBadge
          classes={{
            icon: "icon-local-top-nav:question-white",
          }}
          elements={{
            badge: 1,
          }}
        />
        <IconWithBadge
          classes={{
            icon: "icon-local-top-nav:message-white",
          }}
          elements={{
            badge: 2,
          }}
        />
        <IconWithBadge
          classes={{
            icon: "icon-local-top-nav:note-white",
          }}
          elements={
            {
              // badge: 2,
            }
          }
        />
      </section>
    </Ctrl.Nav.TopElement>
  );
}

// TODO: move to Atoms later
interface IconWithBadgeProps extends IntrinsicElement<"div"> {
  classes: {
    icon: string;
    badge?: string;
  };
  elements?: {
    badge?: JSX.Element | (() => JSX.Element);
    badgePosition?: "top-left";
  };
}

function IconWithBadge(props: IconWithBadgeProps) {
  const [local, others] = splitProps(props, ["classes", "elements"]);
  return (
    <div {...others} class={`relative flex flex-row ${others.class ?? ""}`}>
      <i class={`w-24px h-24px ${local.classes?.icon ?? ""}`} />
      {local.elements?.badge && (
        <span
          class={`absolute -top-10px -right-10px rounded-full w-18px h-18px text-0.5rem items-center justify-center text-center overflow-hidden  bg#highlight ${
            local.classes?.badge ?? ""
          }`}
        >
          {typeof local.elements?.badge === "function" ? local.elements?.badge?.() : local.elements?.badge}
        </span>
      )}
    </div>
  );
}
